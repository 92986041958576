.content__avatar {
  height: 80px;
  width: 80px;
  background-color: #cee3ff;
  margin-top: 25px;
  margin-left: 25px;
  border-radius: 50%;
}

.content__nickname{
  color: #979FAA;
  font-size: 14px;
  position: absolute;
  font-weight: 600;
  -webkit-transform: translate(10px, 35px);
  transform: translate(10px,35px);
}

.content__author {
  color: white;
  font-size: 28px;
  position: absolute;
  font-weight: 600;
  -webkit-transform: translate(10px, 55px);
          transform: translate(10px, 55px);
}

.content {
  grid-area: content;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 69px -16px rgba(34, 60, 80, 0.11);
          box-shadow: 0px 0px 69px -16px rgba(34, 60, 80, 0.11);
}

.content__header {
  background-size: cover;
  background: url("../../../img/banner.jpg") center;
  border-radius: 5px 5px 0px 0px;
  height: 130px;
  -webkit-box-shadow: 0px 5px 31px -2px rgba(34, 60, 80, 0.05);
          box-shadow: 0px 5px 31px -2px rgba(34, 60, 80, 0.05);
  margin-bottom: 20px;
}