.Dialogs {
    display: flex;
    height: 100%;
    width: 100%;
}

.DialogList {
    width: 200px;
    background-color: #FAFBFC;
}

.ActiveDialog {
    width: 742px;
    position: relative;
}


.dialog {
    height: 60px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.3s;
    background: #ECEDEF;
}

.dialog:hover {
    background-color: rgb(185, 185, 185);
}

.link {
    text-decoration: none;
    color: initial;
}

.link:hover {
    background-color: lightgray;
    color: initial;
}

.preview {
    border-radius: 50%;
    background-color: rgb(86, 146, 202);
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.name {
    font-weight: 500;
    text-decoration: none !important;
}