body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
.btn{
    box-shadow: inset 0 0  #ddd;
    border: 1px solid #ced4da;
}

.btn:focus {
    outline: none;
    box-shadow: none !important;
}

.btn{
    box-shadow: none !important;
    border: none;
}

.btn-primary{
    background-color: #6690C1;
    transition: transform .2s linear;
}

.btn-primary:hover{
    background-color: #6690C1;
    transform: translateY(2px);
}

.btn-primary:focus{
    background-color: #6690C1;
}