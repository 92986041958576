body{
    background: #ECEEF0;
}

.App {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
  background-color: #edeef0;
  font-family: 'Montserrat', sans-serif;
}

.wrapper {
  min-height: 100vh;
  width: 1140px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 5fr;
      grid-template-columns: 1fr 5fr;
  -ms-grid-rows: 70px 600px;
      grid-template-rows: 70px 600px;
  grid-gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
      grid-template-areas: 'header header'
 'nav content';
}

.content {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: content;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 69px -16px rgba(34, 60, 80, 0.11);
          box-shadow: 0px 0px 69px -16px rgba(34, 60, 80, 0.11);
    position: relative;
}

.content > *{
    border-radius: 5px;
    background: white;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: fit-content;
}